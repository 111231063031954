import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import Icon from '../../helpers/icon';
import MobilePanelIcons from './mobile-panel-icons';
import Hodur from '../Hodur/Hodur';
import './MobilePanel.scss';
import tt from '../../helpers/translation';
import Pawlink from '../PawLink/PawLink';

const MobilePanel = injectIntl(props => {
  const [showBox, setshowBox] = useState(false);
  const toggleBox = () => {
    setshowBox(!showBox);
  };

  const { intl } = props;

  return (
    <div className="m_homemenu_first menu_container">
      <div id="boton_home_comprarentradas_es" className="ht flex-item">
        <Pawlink type="unknown" destination={tt('/entradas', intl.locale)} parameters={['']}>
          <div className=" m_homemenu_ico_sub ticket">
            <Icon
              icon="Comprar_entradas"
              width="37"
              height="36"
              color="white"
              iconSet={MobilePanelIcons}
            />
            <p>{tt('COMPRAR TICKETS', intl.locale)}</p>
          </div>
        </Pawlink>
      </div>

      <div id="boton_home_hotelentrada_es" className="flex-item" onClick={toggleBox}>
        <div className="m_homemenu_ico_sub hotel">
          <Icon
            icon="Comprar_entradas"
            width="37"
            height="36"
            color="white"
            iconSet={MobilePanelIcons}
          />
          <p>{tt('HOTEL + ENTRADA', intl.locale)}</p>
        </div>
      </div>

      <div id="boton_home_promociones_es" className="flex-item">
        <Pawlink type="named" destination="distr_promociones">
          <div className=" m_homemenu_ico_sub promotion">
            <Icon
              icon="Promociones"
              width="37"
              height="36"
              color="white"
              iconSet={MobilePanelIcons}
            />
            <p>{tt('PROMOCIONES', intl.locale)}</p>
          </div>
        </Pawlink>
      </div>
      <div id="boton_home_checkin_es" className="flex-item">
        <Pawlink type="named" destination="checkin">
          <Icon
            icon="checkin-menu"
            width="44"
            height="42"
            color="white"
            iconSet={MobilePanelIcons}
          />
          <p>Check-In Online</p>
        </Pawlink>
      </div>

      <div id="boton_home_explora_es" className="flex-item">
        <Pawlink type="named" destination="distributiva_parques">
          <div className=" m_homemenu_ico_sub ride">
            <Icon
              icon="Conocenos"
              width="37"
              height="36"
              color="white"
              iconSet={MobilePanelIcons}
            />
            <p>{tt('NUESTROS PARQUES', intl.locale)}</p>
          </div>
        </Pawlink>
      </div>

      <div id="boton_home_disfruta_es" className="flex-item">
        <Pawlink type="named" destination="distributiva_hoteles">
          <div className=" m_homemenu_ico_sub dance">
            <Icon icon="HOTEL" width="37" height="36" color="white" iconSet={MobilePanelIcons} />
            <p>{tt('NUESTROS HOTELES', intl.locale)}</p>
          </div>
        </Pawlink>
      </div>
      {showBox ? <Hodur closePopup={() => toggleBox()} /> : ''}
    </div>
  );
});

export default MobilePanel;
