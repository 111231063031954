import moment from 'moment/moment';
import React from 'react';
import { injectIntl } from 'react-intl';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import tt from '../../../helpers/translation';
import AdultPicker from '../FastBookingComponents/AdultPicker';
import AgeSelectorMobile from '../FastBookingComponents/AgeSelectorMobile';
import Cal from '../FastBookingComponents/DateRange/DateRange';
import KidsPickerMobile from '../FastBookingComponents/KidsPickerMobile';
import PromotionalCode from '../FastBookingComponents/PromotionalCode';
import Room3 from '../FastBookingComponents/room3';
import Buttons from '../../Buttons/Buttons';
import './hotel-entrada.scss';
import redirectFunnelHoteles from '../../../helpers/redirectFunnelHoteles';

const ChildPickerMobile = ({
  adults,
  children,
  handleUpdateChildren,
  handleUpdateChildrenAge,
  errorKid,
}) => (
  <div className="child-picker-mobile">
    <KidsPickerMobile adults={adults} children={children} updateChildren={handleUpdateChildren} />
    <AgeSelectorMobile
      children={children}
      updateAge={handleUpdateChildrenAge}
      showErrors={errorKid}
    />
  </div>
);

class HotelEntrada extends React.Component {
  state = {
    promotionalCodeData: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    errorDate: false,
    errorKid: false,
    lateralVariant: this.props.lateralVariant,
    children: {
      numberOfChildren: 0,
      ages: [null, null, null, null, null, null],
    },
    adults: 2,
  };

  clearErorrs = () => {
    this.setState({ errorDate: false, errorKid: false });
  };

  handleSelectedDateFrom = (date) => {
    this.clearErorrs();
    this.setState({
      dateFrom: moment(date).format('DD-MM-YYYY'),
    });
  };

  handleSelectedDateTo = (date) => {
    this.clearErorrs();
    this.setState({
      dateTo: moment(date).format('DD-MM-YYYY'),
    });
  };

  handleUpdateChildren = (kids) => {
    const nKids = kids.target.value;
    this.setState((state) => {
      const newChildren = { ...state.children, numberOfChildren: parseInt(nKids, 10) };
      return { ...state, children: newChildren };
    });
  };

  handleUpdateChildrenAge = (kidNumber, kidAge) => {
    this.setState((state) => {
      const newAges = state.children.ages.slice();
      newAges[parseInt(kidNumber, 10)] = parseInt(kidAge, 10);
      const newChildren = { ...state.children, ages: newAges };
      return { ...state, children: newChildren };
    });
  };

  handleSelectedAdults = (data) => {
    const adults = data.target.value;
    this.setState({ adults });
  };

  handlePromotionalCode = (handlePromotionalCode) => {
    this.setState({
      promotionalCodeData: handlePromotionalCode,
    });
  };

  getAdults = () => parseInt(this.state.adults);

  handleSendButtonMobile = () => {
    const errors = {};

    if (this.state.children.numberOfChildren > 0) {
      const child = this.state.children.ages
        .slice(0, this.state.children.numberOfChildren)
        .find((e) => e === null);

      if (child === null) {
        errors.errorKid = true;
      }
    }

    if (this.state.dateFrom === undefined || this.state.dateTo === undefined) {
      errors.errorDate = true;
    }

    if (Object.keys(errors).length > 0) {
      this.setState(errors);
      return;
    }

    redirectFunnelHoteles(
      this.props.intl.locale,
      this.state.dateFrom,
      this.state.dateTo,
      this.state.adults,
      this.state.promotionalCodeData,
      this.state.children.ages.slice(0, this.state.children.numberOfChildren) || 0,
      true
    );
  };

  handleSendButton = () => {
    let element;
    if (this.state.children.numberOfChildren > 0) {
      element = this.state.children.ages.find((e) => e === 'Seleccionar');
    }

    if (this.state.dateFrom === undefined || this.state.dateTo === undefined) {
      this.setState({ errorDate: true });
    } else if (element) {
      this.setState({ errorKid: true });
    } else {
      redirectFunnelHoteles(
        this.props.intl.locale,
        this.state.dateFrom,
        this.state.dateTo,
        this.state.adults,
        this.state.promotionalCodeData,
        this.state.children.ages.slice(0, this.state.children.numberOfChildren) || 0,
        false
      );
    }
  };

  render() {
    const ctaButtonMobile = (
      <>
        <Buttons
          onClick={this.handleSendButtonMobile}
          size="small"
          color="orange"
          text={tt('BUSCAR', this.props.intl.locale)}
        />
        {/* <Buttons
          named
          link="checkin"
          size="small"
          color="white"
          text={tt('CHECK-IN ONLINE', this.props.intl.locale)}
        /> */}
      </>
    );
    const ctaButtonDesktop = (
      <>
        <Buttons
          onClick={this.handleSendButton}
          size="small"
          color="orange"
          text={tt('BUSCAR', this.props.intl.locale)}
        />
        {/* <Buttons
          named
          link="checkin"
          size="small"
          color="white"
          text={tt('CHECK-IN ONLINE', this.props.intl.locale)}
        /> */}
      </>
    );

    let has_error2;
    let has_error = '';
    if (this.state.errorDate) has_error = 'has-error';
    if (this.state.errorKid) {
      has_error2 = 'has-error';
    }

    if (this.state.lateralVariant) {
      return (
        <div className="sidefb-hotel-entrada">
          <div className={`calendar-range-wrapper ${has_error}`}>
            <Cal
              selectedDateFrom={(from) => {
                this.handleSelectedDateFrom(from);
              }}
              selectedDateTo={(to) => {
                this.handleSelectedDateTo(to);
              }}
            />
          </div>
          <AdultPicker adultsSelected={this.state.adults} onChange={this.handleSelectedAdults} />
          <div className={`children-selector-wrapper ${has_error2}`}>
            <Room3
              adults={this.getAdults}
              children={this.state.children}
              id="room-configuration4"
            />
          </div>
          {this.state.errorKid ? (
            <p className="has-error-text">
              {tt(
                'Es obligatorio indicar la edad de los niños para continuar.',
                this.props.intl.locale
              )}
            </p>
          ) : null}

          <PromotionalCode onSelectPromotionalCode={this.handlePromotionalCode} />

          <div className="cta-wrapper">
            <MediaServerRender predicted="mobile" hydrated>
              <MediaMatcher
                mobile={ctaButtonMobile}
                tablet={ctaButtonMobile}
                desktop={ctaButtonDesktop}
              />
            </MediaServerRender>
          </div>
        </div>
      );
    }

    const childPickerDesktop = (
      <div className={`children-selector-wrapper ${has_error2}`}>
        <Room3 adults={this.getAdults} children={this.state.children} id="room-configuration3" />
      </div>
    );

    return (
      <div className="hotel-entrada-cont">
        <div className="hotel-entrada-body">
          <div className={`calendar-range-wrapper ${has_error}`}>
            <Cal
              selectedDateFrom={(from) => {
                this.handleSelectedDateFrom(from);
              }}
              selectedDateTo={(to) => {
                this.handleSelectedDateTo(to);
              }}
              onClick={this.clearErrors}
            />
          </div>
          <AdultPicker adultsSelected={this.state.adults} onChange={this.handleSelectedAdults} />

          <MediaServerRender predicted="desktop" hydrated>
            <MediaMatcher
              mobile={
                <ChildPickerMobile
                  adults={this.state.adults}
                  children={this.state.children}
                  handleUpdateChildren={this.handleUpdateChildren}
                  handleUpdateChildrenAge={this.handleUpdateChildrenAge}
                  errorKid={this.state.errorKid}
                />
              }
              desktop={childPickerDesktop}
            />
          </MediaServerRender>

          <div className="cta-wrapper">
            <MediaServerRender predicted="desktop" hydrated>
              <MediaMatcher mobile={ctaButtonMobile} desktop={ctaButtonDesktop} />
            </MediaServerRender>
          </div>
        </div>
        {this.state.errorKid ? (
          <p className="has-error-text">
            {tt(
              'Es obligatorio indicar la edad de los niños para continuar.',
              this.props.intl.locale
            )}
          </p>
        ) : null}
      </div>
    );
  }
}

export default injectIntl(HotelEntrada);
