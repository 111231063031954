import React from 'react';

import moment from 'moment/moment';
import CityPicker from '../FastBookingComponents/city-picker';
import Rooms2 from '../FastBookingComponents/room2';
import './hotel-entrada-vuelo.scss';
import Buttons from '../../Buttons/Buttons';
import Cal from '../FastBookingComponents/DateRange/DateRange';
import tt from '../../../helpers/translation';
import { injectIntl, intlShape } from 'react-intl';

class HotelEntradaVuelo extends React.Component {
  state = {
    dateFrom: undefined,
    dateTo: undefined,
    airportCode: 'AOC',
    roomsdata: {
      rooms: [
        {
          visible: true,
          adults: 1,
          kids: 0,
          ages: [-1, -1, -1, -1, -1, -1],
        },
        {
          visible: false,
          adults: 0,
          kids: 0,
          ages: [-1, -1, -1, -1, -1, -1],
        },
        {
          visible: false,
          adults: 0,
          kids: 0,
          ages: [-1, -1, -1, -1, -1, -1],
        },
      ],
      visible_rooms: 1,
    },
    errors: {
      airportCode: false,
      dateFrom: false,
      dateTo: false,
      kids: false,
    },
    lateralVariant: this.props.lateralVariant,
  };

  handleAirportCode = (event) => {
    this.clearErrors();
    this.setState({
      airportCode: event.target.value,
    });
  };

  handleSelectedDateFrom = (date) => {
    this.clearErrors();
    this.setState({
      dateFrom: moment(date).format('DD-MM-YYYY'),
    });
  };

  handleSelectedDateTo = (date) => {
    this.clearErrors();
    this.setState({
      dateTo: moment(date).format('DD-MM-YYYY'),
    });
  };

  handleChangedRooms = (newconfig) => {
    this.clearErrors();
    this.setState({ rooms: newconfig });
  };

  hasErrors = () =>
    this.state.errors.dateFrom ||
    this.state.errors.dateTo ||
    this.state.errors.airportCode ||
    this.state.errors.kids;

  clearErrors = () => {
    const errors = {
      airportCode: false,
      dateFrom: false,
      dateTo: false,
      kids: false,
    };
    this.setState({ errors });
  };

  paddingZeros = (num, size) => {
    const s = `000${num}`;
    return s.substr(s.length - size);
  };

  computeCodeRoom = (roomid) => {
    if (this.state.roomsdata.rooms[roomid].adults) {
      const adults = '30A'.repeat(this.state.roomsdata.rooms[roomid].adults);
      const kids = this.state.roomsdata.rooms[roomid].ages
        .filter((e) => e >= 0)
        .map((e) => `${this.paddingZeros(e, 2)}C`);
      return adults + kids.join('');
    }
    return null;
  };

  postData = () => {
    const processingErrors = this.state.errors;
    const errArr = [];
    if (
      this.state.roomsdata.rooms.map((e) => {
        e.ages.find((f) => {
          if (f === 'Seleccionar') {
            errArr.push('error');
          }
        });
      })
    ) {
      if (errArr.length) {
        processingErrors.kids = true;
      } else {
        processingErrors.kids = false;
      }
    }

    if (this.state.dateTo === undefined) processingErrors.dateTo = true;
    if (this.state.dateFrom === undefined) processingErrors.dateFrom = true;
    if (this.state.airportCode === undefined) processingErrors.airportCode = true;

    /* Check dates in order */
    const dateFrom = moment(this.state.dateFrom, 'DD-MM-YYYY');
    const dateTo = moment(this.state.dateTo, 'DD-MM-YYYY');
    const diffDays = dateTo.diff(dateFrom, 'days');
    if (diffDays < 1 || diffDays > 200) {
      processingErrors.dateFrom = true;
      processingErrors.dateTo = true;
    }

    this.setState({ errors: processingErrors }, () => {
      if (!this.hasErrors()) {
        const roomConfigArray = [];
        [0, 1, 2].forEach((e) => {
          roomConfigArray.push(this.computeCodeRoom(e));
        });
        const roomConfig = roomConfigArray.filter((e) => e !== null).join();
        const lang = this.props.intl.locale;
        const request = [
          'productType=FlightHotel',
          'LineOfBusiness=Packages',
          'destinationType=CIU',
          'destinationCode=30016919',
          'hotelChain=1139',
          'resident=false',
          `OriginAirportCode=${this.state.airportCode}`,
          `departureDateStr=${dateFrom.format('DD%2FMM%2FYYYY')}`,
          `nights=${diffDays}`,
          `hashRooms=${roomConfig}`,
        ];
        let finalURL;
        switch (lang || 'es') {
          case 'en':
            finalURL = `https://portaventuraworld.bylogitravel.co.uk/packagesasyncstepssp/AvailabilityMaterial?${request.join(
              '&'
            )}`;
            break;
          case 'ru':
            finalURL = `https://portaventuraworld.bylogitravel.co.uk/packagesasyncstepssp/AvailabilityMaterial?${request.join(
              '&'
            )}`;
            break;
          case 'fr':
            finalURL = `https://portaventuraworld.bylogitravel.fr/packagesasyncstepssp/AvailabilityMaterial?${request.join(
              '&'
            )}`;
            break;
          case 'de':
            finalURL = `https://portaventuraworld.bylogitravel.de/packagesasyncstepssp/AvailabilityMaterial?${request.join(
              '&'
            )}`;
            break;
          case 'it':
            finalURL = `https://portaventuraworld.bylogitravel.it/packagesasyncstepssp/AvailabilityMaterial?${request.join(
              '&'
            )}`;
            break;
          default:
            finalURL = `https://portaventuraworld.bylogitravel.com/packagesasyncstepssp/AvailabilityMaterial?${request.join(
              '&'
            )}`;
            break;
        }

        window.open(finalURL, '_blank');
      } else {
        // alert('Errores en la selección');
      }
    });
  };

  render() {
    let has_error = '';
    let has_error2 = '';
    if (this.state.errors.dateFrom || this.state.errors.dateTo) has_error = 'has-error';
    if (this.state.errors.kids) has_error2 = 'has-error';
    if (this.state.lateralVariant) {
      return (
        <div className="fb-side-tab3-container">
          <div className="field">
            <div className="control paw-centered">
              <div id="citypicker-wrapper" className="select is-rounded ">
                <CityPicker onSelectAirport={this.handleAirportCode} />
              </div>
            </div>
          </div>

          <div className="field">
            <div className="control">
              <div id="calendar-range-wrapper" className={`${has_error}`}>
                <Cal
                  selectedDateFrom={(from) => {
                    this.handleSelectedDateFrom(from);
                  }}
                  selectedDateTo={(to) => {
                    this.handleSelectedDateTo(to);
                  }}
                />
              </div>
            </div>
          </div>
          <div id="room-selector-wrapper-paw-centered">
            <Rooms2 values={this.state.roomsdata} action={this.handleChangedRooms} />
          </div>
          <div id="button-click-wrapper" className="">
            <Buttons
              onClick={this.postData}
              size="small"
              color="orange"
              text={tt('RESERVAR AHORA', this.props.intl.locale)}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="fb-tab3-container">
        <div className="tab3-wrapper">
          <div className="airport-selector-wrapper">
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <div className="select is-rounded">
                    <CityPicker onSelectAirport={this.handleAirportCode} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="date-selector-wrapper">
            <div className="field">
              <div className={`calendar-range-wrapper control ${has_error}`}>
                <Cal
                  selectedDateFrom={(from) => {
                    this.handleSelectedDateFrom(from);
                  }}
                  selectedDateTo={(to) => {
                    this.handleSelectedDateTo(to);
                  }}
                />
              </div>
            </div>
          </div>
          <div id="room-selector-wrapper" className={`${has_error2}`}>
            <Rooms2 values={this.state.roomsdata} action={this.handleChangedRooms} />
          </div>
          <div className="cta-wrapper">
            <Buttons
              onClick={this.postData}
              size="small"
              color="orange"
              text={tt('BUSCAR', this.props.intl.locale)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(HotelEntradaVuelo);
