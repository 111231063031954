
const datePickerIcons = {
  IcoMoonType: 'selection',
  icons: [
    {
      icon: {
        paths: [
          'M320 384h128v128h-128zM512 384h128v128h-128zM704 384h128v128h-128zM128 768h128v128h-128zM320 768h128v128h-128zM512 768h128v128h-128zM320 576h128v128h-128zM512 576h128v128h-128zM704 576h128v128h-128zM128 576h128v128h-128zM832 0v64h-128v-64h-448v64h-128v-64h-128v1024h960v-1024h-128zM896 960h-832v-704h832v704z',
        ],
        tags: [
          'calendar',
          'date',
          'schedule',
          'time',
          'day',
        ],
        defaultCode: 59731,
        grid: 16,
        attrs: [],
      },
      attrs: [],
      properties: {
        ligatures: 'calendar, date',
        name: 'calendar',
        order: 614,
        id: 84,
        prevSize: 32,
        code: 59863,
      },
      setIdx: 3,
      setId: 1,
      iconIdx: 83,
    },

  ],
};

export default datePickerIcons;
