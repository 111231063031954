import React from 'react';
import './kids-picker-mobile.scss';
import { injectIntl } from 'react-intl';
import tt from '../../../helpers/translation';

const KidsPickerMobile = ({
  adults, children, updateChildren, intl: { locale },
}) => (
  <div className="adults-kids-wrapper">
    <div className="adults-picker-body">
      <div className="select is-rounded">
        <select
          name="kids-select"
          value={children.numberOfChildren}
          onChange={updateChildren}
        >
          {[0, 1, 2, 3, 4, 5].filter(i => i < 7 - adults).map(i => (
            <option key={i} value={i}>{i} {tt('Niños', locale)}</option>
          ))}
        </select>
      </div>
    </div>

  </div>
);

export default injectIntl(KidsPickerMobile);
