import React from 'react';
// import './city-picker.scss';
import { injectIntl, intlShape } from 'react-intl';
import tt from '../../../helpers/translation'
import citiesJSON from './cities.json';

class CityPicker extends React.Component {
  state = {
  };
  


  render() {
    const { locale } = this.props.intl;
    const { onSelectAirport: handleSelect } = this.props;

    const transCountries = {
      es: tt('España', locale),
      fin: tt('Finlandia', locale),
      fra: tt('Francia', locale),
      ger: tt('Alemania', locale),
      it: tt('Italia', locale),
      por: tt('Portugal', locale),
      uk: tt('Reino Unido', locale),
    };


  
    const locales = citiesJSON.countries;
    const countries = citiesJSON.countries[locale];
    const citiesFin = citiesJSON.countries[locale][transCountries.fin];
    const citiesFra = citiesJSON.countries[locale][transCountries.fra];
    const citiesGer = citiesJSON.countries[locale][transCountries.ger];
    const citiesIt = citiesJSON.countries[locale][transCountries.it];
    const citiesPor = citiesJSON.countries[locale][transCountries.por];
    const citiesSpa = citiesJSON.countries[locale][transCountries.es];
    const citiesUK = citiesJSON.countries[locale][transCountries.uk];
    const arrOPT = [];

  
    const arryFin = Object.values(citiesFin).map(city => ({ name: city.name, code: city.code }));
    
    const arryFr = Object.values(citiesFra).map(city => ({ name: city.name, code: city.code }));
    const arryGer = Object.values(citiesGer).map(city => ({ name: city.name, code: city.code }));
    const arryIt = Object.values(citiesIt).map(city => ({ name: city.name, code: city.code }));
    const arryPor = Object.values(citiesPor).map(city => ({ name: city.name, code: city.code }));
    const arrySpa = Object.values(citiesSpa).map(city => ({ name: city.name, code: city.code }));
    const arryUK = Object.values(citiesUK).map(city => ({ name: city.name, code: city.code }));

    for (let i = 0; i < Object.keys(countries).length; i += 1) {
      const value = (
        <optgroup label={`${Object.keys(countries)[i]}`}>
          {Object.keys(countries)[i] === tt('Finlandia', locale) && arryFin.map(element => <option value={element.code}>{element.name}</option>)}
          {Object.keys(countries)[i] === tt('Alemania', locale) && arryGer.map(element => <option value={element.code}>{element.name}</option>)}
          {Object.keys(countries)[i] === tt('Portugal', locale) && arryPor.map(element => <option value={element.code}>{element.name}</option>)}
          {Object.keys(countries)[i] === tt('Italia', locale) && arryIt.map(element => <option value={element.code}>{element.name}</option>)}
          {Object.keys(countries)[i] === tt('España', locale) && arrySpa.map(element => <option value={element.code}>{element.name}</option>)}
          {Object.keys(countries)[i] === tt('Francia', locale) && arryFr.map(element => <option value={element.code}>{element.name}</option>)}
          {Object.keys(countries)[i] === tt('Reino Unido', locale) && arryUK.map(element => <option value={element.code}>{element.name}</option>)}
        </optgroup>
      );
      arrOPT.push(value);
    }


    return (
      <select id="cities" name="city-select" defaultValue={this.state.value} onChange={handleSelect}>
          {arrOPT.map(element => element)}
        </select>
    );
  }
}

export default injectIntl(CityPicker);
