import React from 'react';
import moment from 'moment';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { injectIntl, intlShape } from 'react-intl';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import Icon from '../../../../helpers/icon';
import datePickerIcons from '../date-picker-icons';
import tt from '../../../../helpers/translation';
import './style.scss';

import 'moment/locale/es';
import 'moment/locale/ca';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/ru';
import 'moment/locale/it';

class Example extends React.Component {
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
    };

    this.updateParentFrom = this.props.selectedDateFrom;
    this.updateParentTo = this.props.selectedDateTo;
  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from }, () => {
      this.updateParentFrom(from);
    });
  }

  handleToChange(to) {
    this.setState({ to }, () => {
      this.updateParentTo(to);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    const today = new Date();

    const isBrowserTrue = (
      <div className="DateRangeWrapper">
        <div className="InputFromTo">
          <div className="InputFromTo-from">
            <Icon
              icon="calendar"
              width="15"
              height="15"
              color="#5E5E5E"
              iconSet={datePickerIcons}
            />{' '}
            &nbsp;
            <DayPickerInput
              ref={(el) => (this.from = el)}
              value={from}
              placeholder={tt('Llegada', this.props.intl.locale)}
              formatDate={formatDate}
              parseDate={parseDate}
              format="l"
              firstDayOfWeek={1}
              dayPickerProps={{
                selectedDays: [from, { from, to }],
                disabledDays: {
                  after: to,
                  before: today,
                },
                month: new Date(2022, 3),
                toMonth: to,
                modifiers,
                numberOfMonths: 2,
                firstDayOfWeek: 1,
                locale: this.props.intl.locale,
                localeUtils: MomentLocaleUtils,
                onDayClick: () => this.to.getInput().focus(),
              }}
              inputProps={{
                id: 'fromto-llegada',
                class: 'pick-date',
              }}
              onDayChange={this.handleFromChange}
              locale={this.props.intl.locale}
            />
          </div>{' '}
          <div className="InputFromTo-to">
            <Icon
              icon="calendar"
              width="15"
              height="15"
              color="#5E5E5E"
              iconSet={datePickerIcons}
            />{' '}
            &nbsp;
            <DayPickerInput
              ref={(el) => (this.to = el)}
              value={to}
              placeholder={tt('Salida', this.props.intl.locale)}
              formatDate={formatDate}
              parseDate={parseDate}
              format="l"
              firstDayOfWeek={1}
              dayPickerProps={{
                selectedDays: [from, { from, to }],
                disabledDays: { before: this.addDays(from, 1) },
                modifiers,
                month: from || new Date(2022, 3),
                fromMonth: from,
                numberOfMonths: 2,
                firstDayOfWeek: 1,
                localeUtils: MomentLocaleUtils,
                locale: this.props.intl.locale,
              }}
              inputProps={{
                class: 'pick-date',
              }}
              onDayChange={this.handleToChange}
              locale={this.props.intl.locale}
            />
          </div>
        </div>
      </div>
    );

    const isMobileTrue = (
      <div className="DateRangeWrapper">
        <div className="InputFromTo">
          <div className="InputFromTo-from">
            <Icon
              icon="calendar"
              width="15"
              height="15"
              color="#5E5E5E"
              iconSet={datePickerIcons}
            />{' '}
            &nbsp;
            <DayPickerInput
              ref={(el) => (this.from = el)}
              value={from}
              placeholder={tt('Llegada', this.props.intl.locale)}
              formatDate={formatDate}
              parseDate={parseDate}
              format="l"
              firstDayOfWeek={1}
              dayPickerProps={{
                selectedDays: [from, { from, to }],
                disabledDays: {
                  after: to,
                  before: today,
                },
                month: new Date(2022, 3),
                toMonth: to,
                modifiers,
                numberOfMonths: 1,
                firstDayOfWeek: 1,
                onDayClick: () => this.to.getInput().focus(),
                localeUtils: MomentLocaleUtils,
                locale: this.props.intl.locale,
              }}
              onDayChange={this.handleFromChange}
              locale={this.props.intl.locale}
              inputProps={{ readOnly: true }}
            />
          </div>{' '}
          <div className="InputFromTo-to">
            <Icon
              icon="calendar"
              width="15"
              height="15"
              color="#5E5E5E"
              iconSet={datePickerIcons}
            />{' '}
            &nbsp;
            <DayPickerInput
              ref={(el) => (this.to = el)}
              value={to}
              placeholder={tt('Salida', this.props.intl.locale)}
              formatDate={formatDate}
              parseDate={parseDate}
              format="l"
              firstDayOfWeek={1}
              dayPickerProps={{
                selectedDays: [from, { from, to }],
                disabledDays: { before: this.addDays(from, 1) },
                modifiers,
                month: from || new Date(2022, 3),
                fromMonth: from,
                numberOfMonths: 1,
                firstDayOfWeek: 1,
                localeUtils: MomentLocaleUtils,
                locale: this.props.intl.locale,
              }}
              onDayChange={this.handleToChange}
              locale={this.props.intl.locale}
              inputProps={{ readOnly: true }}
            />
          </div>
        </div>
      </div>
    );

    return (
      <MediaServerRender predicted="desktop" hydrated>
        <MediaMatcher mobile={isMobileTrue} desktop={isBrowserTrue} />
      </MediaServerRender>
    );
  }
}

export default injectIntl(Example);
