import './TitleH1.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Pawlink from '../../PawLink/PawLink';

const TitleH1 = props => {
  const {
    data: { title, description, linkText, linkUrl },
  } = props;

  let hasSubtitle;
  let hasLinkText;

  if (linkUrl) {
    hasLinkText = <div className="link-item">{linkText && <a href={linkUrl}>{linkText}</a>}</div>;
  } else if (linkText && !linkUrl) {
    hasLinkText = (
      <div className="link-item">
        {linkText && (
          <Pawlink type="named" destination="distr_promociones">
            {linkText}
          </Pawlink>
        )}
      </div>
    );
  }

  if (description) {
    hasSubtitle = <p className="TitleH1-subtitle">{description}</p>;
  }

  return (
    <div className="TitleH1-container">
      <h1 className="TitleH1-title">{title}</h1>
      {hasSubtitle}
      {hasLinkText}
    </div>
  );
};

TitleH1.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    linkText: PropTypes.string,
    linkUrl: PropTypes.string,
  }),
};

TitleH1.defaultProps = {
  data: {
    title: 'TITULO STANDARD',
  },
};

export default TitleH1;
