import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import HotelEntrada from './HotelEntrada/HotelEntrada';
import Pawlink from '../PawLink/PawLink';
import HotelEntradaVuelo from './HotelEntradaVuelo/HotelEntradaVuelo';
import './fast-booking.scss';
import tt from '../../helpers/translation';

class FastBooking extends React.Component {
  state = {
    value: 0,
  };

  handleChange = value => {
    this.setState({ value });
  };

  render() {
    this.sheetsManager = new Map();

    return (
      <>
        <div className="fb-container">
          <div className="fb-header">
            <div
              className={this.state.value === 0 ? 'active tab' : 'tab'}
              onClick={() => this.handleChange(0)}
            >
              {tt('Hotel + Entradas', this.props.intl.locale)}
            </div>
            <Pawlink
              type="unknown"
              destination={tt('/entradas', this.props.intl.locale)}
              parameters={['']}
            >
              <div className={this.state.value === 1 ? 'active link' : 'link'}>
                {tt('COMPRAR ENTRADAS', this.props.intl.locale)}
              </div>
            </Pawlink>
            <div
              className={this.state.value === 2 ? 'active tab' : 'tab'}
              onClick={() => this.handleChange(2)}
            >
              {tt('Hotel + Entradas + Vuelo', this.props.intl.locale)}
            </div>

            <Pawlink type="named" destination="pase_anual" parameters={['']}>
              <div className={this.state.value === 3 ? 'active link last' : 'link last'}>
                {tt('PASE ANUAL', this.props.intl.locale)}
              </div>
            </Pawlink>
          </div>
          {this.state.value === 0 && (
            <div className="fb-content">
              <HotelEntrada />
              <div className="has-text-centered">
                <Pawlink
                  type="anchor"
                  destination="http://www.portaventuraworld.com/traslados-portaventura"
                  className="rent-a-car"
                >
                  {tt('Ver traslados y alquiler de coches', this.props.intl.locale)}
                </Pawlink>
              </div>
            </div>
          )}
          {this.state.value === 1 && (
            <div className="fb-content">
              <div className="has-text-centered">
                <Pawlink
                  type="anchor"
                  destination="http://www.portaventuraworld.com/traslados-portaventura"
                  className="rent-a-car"
                >
                  {tt('Ver traslados y alquiler de coches', this.props.intl.locale)}
                </Pawlink>
              </div>
            </div>
          )}
          {this.state.value === 2 && (
            <div className="fb-content">
              <HotelEntradaVuelo />
              <div className="has-text-centered">
                <Pawlink
                  type="anchor"
                  destination="http://www.portaventuraworld.com/traslados-portaventura"
                  className="rent-a-car"
                >
                  {tt('Ver traslados y alquiler de coches', this.props.intl.locale)}
                </Pawlink>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

FastBooking.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectIntl(FastBooking);
