import React from 'react';
import './rooms.scss';
import onClickOutside from 'react-onclickoutside';
import { injectIntl, intlShape } from 'react-intl';
import NumberSelector from './NumberSelector.js';
import tt from '../../../helpers/translation';

class Rooms3 extends React.Component {
  state = {
    showMainContainer: false,
    showPanelClass: 'panel-one-room',
    showPanelColumns: 'is-12',
    rooms: [
      {
        kids: 0,
        ages: [],
      },
    ],
    visible_rooms: 1,
    errorMaxPeopleReached: false,
    children: this.props.children,
  };

  componentDidMount = () => {
    this.updateMainDiv();
  };

  handleClickMain = () => {
    this.setState(
      {
        showMainContainer: !this.state.showMainContainer,
      },
      () => this.updateMainDiv()
    );
  };

  handleClickOutside = (evt) => {
    this.setState({ showMainContainer: false });
  };

  updatePanelSize = () => {
    const panel_class = 'panel-one-room';
    const panel_columns = 'is-12';

    this.setState({
      showPanelClass: panel_class,
      showPanelColumns: panel_columns,
    });
  };

  // roomPeople = roomId => this.state.rooms[roomId].kids;

  countKids = () => this.state.children.numberOfChildren;

  updateMainDiv = () => {
    let textKids;
    if (this.countKids() === 0) {
      textKids = `0 ${tt('niños', this.props.intl.locale)} (<12 ${tt(
        'años',
        this.props.intl.locale
      )})`;
    } else if (this.countKids() === 1) {
      textKids = `${this.countKids()} ${tt('Niño', this.props.intl.locale)}`;
    } else {
      textKids = `${this.countKids()} ${tt('Niños', this.props.intl.locale)}`;
    }
    this.setState({ mainDivText: `${textKids}` });
  };

  addKid = () => {
    const adults = this.props.adults();
    const nchildren = this.state.children.numberOfChildren;
    const { children } = this.state;
    if (adults + nchildren < 6) {
      children.numberOfChildren += 1;
      children.ages[children.numberOfChildren - 1] = 'Seleccionar';
      this.setState({ children }, () => this.updateMainDiv());
    }
  };

  removeKid = () => {
    if (this.state.children.numberOfChildren > 0) {
      const { children } = this.state;

      children.numberOfChildren -= 1;
      children.ages[children.numberOfChildren] = -1;
      this.setState({ children }, () => this.updateMainDiv());
    }
  };

  updateAge = (event, kid) => {
    const currentChildren = this.state.children;
    currentChildren.ages[kid] = parseInt(event.target.value);
    this.setState({ children: currentChildren });
  };

  acceptConfig = () => {
    this.setState(
      {
        showMainContainer: false,
      },
      () => {
        const roomsdata = {
          rooms: this.state.rooms,
          visible_rooms: this.state.visible_rooms,
        };
        this.props.action(roomsdata);
      }
    );
  };

  kidsSelector = (kid) => {
    const edad = this.state.children.ages[kid];
    return (
      <div className="select is-rounded kidsselect">
        <select value={edad} onChange={(event) => this.updateAge(event, kid)} name="age-kids">
          <option disabled value="Seleccionar">
            {tt('Elige', this.props.intl.locale)}
          </option>
          {/* <option value="0">0 {tt('años', this.props.intl.locale)}</option>
          <option value="1">1 {tt('años', this.props.intl.locale)}</option> */}
          <option value="2">2 {tt('años', this.props.intl.locale)}</option>
          <option value="3">3 {tt('años', this.props.intl.locale)}</option>
          <option value="4">4 {tt('años', this.props.intl.locale)}</option>
          <option value="5">5 {tt('años', this.props.intl.locale)}</option>
          <option value="6">6 {tt('años', this.props.intl.locale)}</option>
          <option value="7">7 {tt('años', this.props.intl.locale)}</option>
          <option value="8">8 {tt('años', this.props.intl.locale)}</option>
          <option value="9">9 {tt('años', this.props.intl.locale)}</option>
          <option value="10">10 {tt('años', this.props.intl.locale)}</option>
          <option value="11">11 {tt('años', this.props.intl.locale)}</option>
          <option value="12">12 {tt('años', this.props.intl.locale)}</option>
        </select>
      </div>
    );
  };

  mainContainer = () => (
    <div className="panel-relative">
      <div className={`show-panel ${this.state.showPanelClass}`}>
        <div className="rooms-wrapper">
          <div className="select-rooms columns">
            <div className="column">
              <div className="columns">
                <div className="column is-6 room-guests">
                  {tt('Niños', this.props.intl.locale)} (2-12)
                </div>
                <div className="column is-6 room-guest-selector">
                  <NumberSelector
                    value={this.state.children.numberOfChildren}
                    onDec={() => this.removeKid()}
                    onInc={() => this.addKid()}
                  />
                </div>
              </div>
              {this.state.children.numberOfChildren > 0 && (
                <div className="kids-ages-wrapper">
                  <div className="kids-ages">{tt('Edad de los niños', this.props.intl.locale)}</div>
                  <div className="columns  is-multiline kids-selectors">
                    {Array.apply('*', { length: this.state.children.numberOfChildren }).map(
                      (e, i) => (
                        <div className="kid-column column is-half" key={`key-selector-${e}-${i}`}>
                          {' '}
                          {this.kidsSelector(i)}{' '}
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    return (
      <div className="rooms-container">
        <div className="input is-rounded is-narrow" id={this.props.id}>
          <span onClick={this.handleClickMain}>{this.state.mainDivText}</span>
          <div>{this.state.showMainContainer && this.mainContainer()}</div>
        </div>
      </div>
    );
  }
}

export default injectIntl(onClickOutside(Rooms3));
