import React from 'react';
import HotelEntrada from '../FastBooking/HotelEntrada/HotelEntrada';
// import menuIcons from '../Layout/MainMenu/menuIcons';

import Icon from '../../helpers/icon';

import './Hodur.scss';

const Hodur = (props) => {
  const { closePopup } = props;

  return (
    <div className="modal-popup-fb">
      <div className="close-button-fb" onClick={closePopup}>
        {/* <Icon
          icon="cross"
          width="25"
          height="25"
          class="is-center"
          color="#ffffff"
          iconSet={menuIcons}
        /> */}
      </div>
      <div className="container-popup-fb">
        <HotelEntrada />
      </div>
    </div>
  );
};

export default Hodur;
