import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import tt from '../../../helpers/translation';


class AdultPicker extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <div className="adults-kids-wrapper">
          <div className="adults-picker-body">
            <div className="select is-rounded">
              <select
                name="adults-select"
                value={this.props.adultsSelected}
                onChange={this.props.onChange}
              >
                <option value="1">1 {tt('Adulto', this.props.intl.locale)}</option>
                <option value="2">2 {tt('Adultos', this.props.intl.locale)}</option>
                <option value="3">3 {tt('Adultos', this.props.intl.locale)}</option>
                <option value="4">4 {tt('Adultos', this.props.intl.locale)}</option>
                <option value="5">5 {tt('Adultos', this.props.intl.locale)}</option>
                <option value="6">6 {tt('Adultos', this.props.intl.locale)}</option>
              </select>
            </div>
          </div>

        </div>
      </React.Fragment>

    );
  }
}

export default injectIntl(AdultPicker);
