/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import FastBooking from '../src/components/FastBooking/FastBooking';
import Hela from '../src/components/Hela/Hela';
import Hod from '../src/components/Hod/Hod';
import MobilePanel from '../src/components/MobilePanel/MobilePanel';
import PawPanel from '../src/components/PawPanel/PawPanel';
import HeimdallHome from '../src/components/HeimdallHome/heimdallHome';
import Promotions from '../src/components/Auto/Promotions/Promotions';
import H1 from '../src/components/Titles/TitleH1/TitleH1';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import TitleH3 from '../src/components/Titles/TitleH3/TitleH3';
import Tyr from '../src/components/Tyr/Tyr';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import Layout from '../src/components/Layout/Layout';
import './index.scss';

const PrepareforHela = ({ edges }) => ({
  hotels: edges.map(({ node }, index) => ({
    hotelCardData: {
      description: node.description,
      id: String(index),
      orangeButtonText: node.cta_text,
      orangeButtonURL: node.cta_path,
      stars: node.stars,
      title: node.title,
      whiteButtonURL: node.title_path,
    },
    images: [node.localImage1.childImageSharp.fluid, node.localImage2.childImageSharp.fluid],
    price: String(node.price),
  })),
});

const PrepareforHelaMobile = ({ edges }) => ({
  hotels: edges.map(({ node }, index) => {
    const link = node.cta_path;
    const ctaLink = link;
    return {
      hotelCardData: {
        description: node.description,
        id: String(index),
        orangeButtonText: node.cta_text,
        orangeButtonURL: ctaLink,
        stars: node.stars,
        title: node.title,
        whiteButtonURL: node.title_path,
      },
      images: [node.localImage1.childImageSharp.fluid, node.localImage2.childImageSharp.fluid],
      price: String(node.price),
    };
  }),
});

const isInViewport = (elem) => {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const update_container_tickets = () => {
  const fb = document.querySelector('div.fb-container');
  if (document.querySelector('div.container-tickets')) {
    if (isInViewport(fb)) {
      document.querySelector('div.container-tickets').className = 'container-tickets home';
    } else {
      document.querySelector('div.container-tickets').className = 'container-tickets home open';
    }
  }
};

const preparePromotion = (data) => JSON.parse(JSON.stringify(data));

const IndexPage = ({
  pageContext: { locale, url, pageName },
  data: {
    allPromoPanelBlock,
    allHotelesBlock,
    allPromotionSublock,
    allHomeMobileImage,
    allHomeSeoData,
    allHomeRichData,
    allDestacadosBlock,
    allDiscoverParksHomeImages,
  },
}) => {
  useEffect(() => {
    if (document.querySelector('div.container-tickets') != null) {
      document.querySelector('div.container-tickets').className = 'container-tickets home';
      window.addEventListener('scroll', update_container_tickets, false);
    }
    if (
      typeof window !== 'undefined' &&
      typeof global.dataLayer !== 'undefined' &&
      typeof global.dataLayer.push === 'function'
    ) {
      global.dataLayer.push({ event: 'optimize.activate' });
    }
    return () => {
      if (document.querySelector('div.container-tickets') != null) {
        window.removeEventListener('scroll', update_container_tickets, false);
        document.querySelector('div.container-tickets').className = 'container-tickets';
      }
    };
  }, []);

  const secondTitleData = {
    title: tt('ÚLTIMAS NOVEDADES EN PORTAVENTURA WORLD', locale),
    description: tt(
      'Explora todo un mundo hecho de diversión y conoce nuestros asombrosos parques temáticos, los hoteles tematizados más increíbles y la mejor oferta de entretenimiento, relax, gastronomía y shopping que puedas imaginar.',
      locale
    ),
  };

  const titlePromotions = {
    title: tt('PROMOCIONES DESTACADAS', locale),
    description: tt(
      'Consulta las últimas promociones y benefíciate de descuentos especiales para vivir la experiencia PortAventura World al mejor precio.',
      locale
    ),
    linkText: tt('Ver todas las promociones', locale),
  };

  // const titleActivities = {
  //   title: tt('EXPERIENCIAS DESTACADAS', locale),
  //   description: tt(
  //     'Siente una parte de PortAventura World desde tu casa. Aquí encontrarás juegos, manualidades, recetas y un sinfín de actividades para hacer en familia.',
  //     locale
  //   ),
  //   linkText: tt('VER EXPERIENCIAS', locale),
  //   linkUrl: tt('/indoor-experience/', locale),
  // };

  const titleHotels = {
    title: tt('HOTELES DESTACADOS', locale),
    description: tt(
      'Benefíciate de los múltiples beneficios de alojarte en los hoteles tematizados de PortAventura World y vive una experiencia única en familia',
      locale
    ),
    linkItem: tt('Ver todos los hoteles', locale),
    linkUrl: tt('/hoteles', locale),
  };
  const thirdTitleData = {
    title: tt('DESCUBRE NUESTROS PARQUES', locale),
    description: tt(
      'Déjate seducir por todas las experiencias que nuestros parques pueden ofrecerte. ¡En PortAventura World tenemos diversión para todos!',
      locale
    ),
  };

  const prepareForTyr = ({ edges }) => ({
    cards: edges.map(({ node }) => ({
      MainImage: node.MainImage.childImageSharp.fluid,
      Image: node.Image,
      altImage: node.alt_image,
      Title: node.Title,
      TitleURL: node.TitleURL,
      Text: node.Text,
      button1: node.button2,
      button2: node.button1,
    })),
  });

  const titleHome = {
    title: allPromoPanelBlock.edges[0].node.title,
    subtitle: allPromoPanelBlock.edges[0].node.subtitle,
    ctaText: allPromoPanelBlock.edges[0].node.ctaText,
    // textAlign: allPromoPanelBlock.edges[0].node.textAlign,
    textAlign: 'izquierda',
    // textAlignAlt: allPromoPanelBlock.edges[0].node.textAlignAlt,
    textAlignAlt: 'center',
    // textAlign: allPromoPanelBlock.edges[0].node.videotextAlign,
    videotextAlign: 'center',
    // videotextAlign: allPromoPanelBlock.edges[0].node.videotextAlignAlt,
    videotextAlignAlt: 'center',
    cta: allPromoPanelBlock.edges[0].node.cta,
    cta2: allPromoPanelBlock.edges[0].node.cta2,
    ctaText2: allPromoPanelBlock.edges[0].node.ctaText2,
    ctaDirection: allPromoPanelBlock.edges[0].node.ctaDirection,
    cta2Direction: allPromoPanelBlock.edges[0].node.cta2Direction,
    // ctaVideoDirection: allPromoPanelBlock.edges[0].node.ctaVideoDirection,
    // cta2VideoDirection: allPromoPanelBlock.edges[0].node.cta2VideoDirection,
    ctaVideoDirection: 'Izquierda',
    cta2VideoDirection: 'Izquierda',
    video: allPromoPanelBlock.edges[0].node.video,
    video_title: allPromoPanelBlock.edges[0].node.video_title,
    video_subtitle: allPromoPanelBlock.edges[0].node.video_subtitle,
    ctaVideo: allPromoPanelBlock.edges[0].node.ctaVideo,
    ctaVideoText: allPromoPanelBlock.edges[0].node.ctaVideoText,
    ctaVideoText2: allPromoPanelBlock.edges[0].node.ctaVideoText2,
    ctaVideo2: allPromoPanelBlock.edges[0].node.ctaVideo2,
    imgDesktop: allPromoPanelBlock.edges[0].node.localImage.childImageSharp.fluid,
    imgMobile: allHomeMobileImage.edges[0].node.localImage.childImageSharp.fluid,
    buttonImageType1: allPromoPanelBlock.edges[0].node.buttonImageType1,
    buttonImageType2: allPromoPanelBlock.edges[0].node.buttonImageType2,
    buttonVideoType1: allPromoPanelBlock.edges[0].node.buttonVideoType1,
    buttonVideoType2: allPromoPanelBlock.edges[0].node.buttonVideoType2,
    buttonMobileType1: allPromoPanelBlock.edges[0].node.button1_mobile,
    buttonMobileType2: allPromoPanelBlock.edges[0].node.button2_mobile,
    mobile_title: allPromoPanelBlock.edges[0].node.mobile_title,
    mobile_subtitle: allPromoPanelBlock.edges[0].node.mobile_subtitle,
    mobile_cta_copy_one: allPromoPanelBlock.edges[0].node.mobile_cta_copy_one,
    mobile_cta_path_one: allPromoPanelBlock.edges[0].node.mobile_cta_path_one,
    mobile_cta_copy_two: allPromoPanelBlock.edges[0].node.mobile_cta_copy_two,
    mobile_cta_path_two: allPromoPanelBlock.edges[0].node.mobile_cta_path_two,
  };

  const isMobileHela = <Hela locale={locale} data={PrepareforHelaMobile(allHotelesBlock)} />;
  const isDesktopHela = <Hela locale={locale} data={PrepareforHela(allHotelesBlock)} />;
  const isMobilePromotion = (
    <Promotions panelData={preparePromotion(allPromotionSublock.edges)} locale={locale} />
  );
  // const isMobileActivities = (
  //   <Promotions panelData={preparePromotion(allActivitiesSublock.edges)} />
  // );
  const isDesktopPromotion = <Promotions panelData={allPromotionSublock.edges} locale={locale} />;
  // const isDesktopActivities = (
  //   <>
  //     <Promotions panelData={data.allActivitiesSublock.edges} />
  //     <br />
  //     <br />
  //     <br />
  //   </>
  // );

  return (
    <Layout locale={locale} pageName={pageName} layoutFromOld>
      <SEO
        title={allHomeSeoData.edges[0].node._0.title}
        description={allHomeSeoData.edges[0].node._1.description}
        pathname={url}
        lang={locale}
      />
      <Hod
        data={allHomeRichData.edges[0].node.rich_markup}
        url={url}
        img={allPromoPanelBlock.edges[0].node.image}
      />
      <div className="index-container">
        <HeimdallHome promoData={titleHome} />
      </div>
      <MobilePanel /> {/* SE PONE AQUI Y NO DENTRO DEL GENERAL-INDEX PARA NO CORTAR EL FONDO  */}
      <div className="general-index index-home">
        <div className="main-index">
          <FastBooking />
          {/* <div className="bolasNavidad1-container">
            <img src={bolaNavidad1} className="bolaNavidad1" alt="bolaNavidad" />
          </div> */}
          <BlueArrow />

          <div className="main-container">
            {/* <div className="promo-container t-page-block-1">
              <H1 data={titlePromotions} />
              <MediaServerRender predicted="desktop" hydrated>
                <MediaMatcher
                  mobile={isMobilePromotion}
                  tablet={isMobilePromotion}
                  desktop={isDesktopPromotion}
                />
              </MediaServerRender>
            </div> */}
            <div className="paw-container t-page-block-2">
              <H2 data={secondTitleData} />
              <PawPanel data={allDestacadosBlock.edges} />
            </div>
            <div className="hotel-container t-page-block-3">
              {/* <img src={bolaNavidad2} className="bolaNavidad2" alt="bolaNavidad" /> */}
              <TitleH3 data={titleHotels} />
              <MediaServerRender predicted="desktop" hydrated>
                <MediaMatcher mobile={isMobileHela} tablet={isMobileHela} desktop={isDesktopHela} />
              </MediaServerRender>
            </div>
            <div className="park-container t-page-block-4">
              {/* <div className="bolasNavidad3-container">
                <img src={bolaNavidad3} className="bolaNavidad3" alt="bolaNavidad" />
              </div> */}

              <TitleH3 data={thirdTitleData} />
              <Tyr data={prepareForTyr(allDiscoverParksHomeImages)} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const GatsbyQuery = () => graphql`
  query HomeQuery($locale: String!) {
    allHomeSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    homeCanonical {
      url
    }
    allPromotionSublock(
      filter: { lang: { eq: $locale }, type: { eq: "Top Promociones" } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          title
          colorTitle
          description
          colorDescription
          cta1
          noRel1
          ctaText1
          cta2
          ctaText2
          noRel2
        }
      }
    }
    # allActivitiesSublock(
    #   filter: { lang: { eq: $locale }, type: { eq: "Top Promociones" } }
    #   sort: { fields: position, order: ASC }
    # ) {
    #   edges {
    #     node {
    #       image
    #       localImage {
    #         childImageSharp {
    #           fluid(quality: 100) {
    #             ...GatsbyImageSharpFluid
    #           }
    #         }
    #       }
    #       alt
    #       title
    #       description
    #       cta1
    #       noRel1
    #       ctaText1
    #       cta2
    #       ctaText2
    #       noRel2
    #     }
    #   }
    # }
    allHomeMobileImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allDestacadosBlock(filter: { lang: { eq: $locale } }, sort: { fields: position, order: ASC }) {
      edges {
        node {
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          altImage
          title
          description
          cta
          noRel
          blank
          ctaText
          see_more_link
          see_more_text
        }
      }
    }
    allPromoPanelBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subtitle
          ctaText
          cta
          cta2
          ctaText2
          video
          ctaDirection
          cta2Direction
          ctaVideoDirection
          cta2VideoDirection
          video_title
          video_subtitle
          ctaVideo
          ctaVideoText
          ctaVideoText2
          buttonImageType1
          buttonImageType2
          buttonVideoType1
          buttonVideoType2
          button1_mobile
          button2_mobile
          ctaVideo2
          mobile_title
          mobile_subtitle
          mobile_cta_copy_one
          mobile_cta_path_one
          mobile_cta_copy_two
          mobile_cta_path_two
          image
          localImage {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1620, maxHeight: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allHotelesBlock(limit: 2, filter: { lang: { eq: $locale } }) {
      edges {
        node {
          image1
          image2
          localImage1 {
            childImageSharp {
              fluid(maxWidth: 650, maxHeight: 360, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          localImage2 {
            childImageSharp {
              fluid(maxWidth: 650, maxHeight: 360, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }

          title
          title_path
          description
          cta_text
          cta_path
          price
          stars
        }
      }
    }
    allHomeRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
    allDiscoverParksHomeImages(
      limit: 3
      filter: { lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          MainImage {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          Image
          alt_image
          Title
          TitleURL
          Text
          button1 {
            cta
            size
            color
            ctaText
          }
          button2 {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
  }
`;
