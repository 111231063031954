import React from 'react';
import './kids-picker-mobile.scss';
import { injectIntl, intlShape } from 'react-intl';
import tt from '../../../helpers/translation';

class AgeSelectorMobile extends React.PureComponent {
  render() {
    const {
      children, showErrors, updateAge, intl: { locale },
    } = this.props;

    return (
      <div className="ages-container">
        { [0, 1, 2, 3, 4, 5]
          .filter(i => i < children.numberOfChildren)
          .map(i => (
            <div className={`select is-rounded${showErrors && children.ages[i] === null ? ' has-error' : ''}`} key={i}>
              <select
                name={`age-select[${i}]`}
                value={children.ages[i]}
                onChange={e => updateAge(i, e.target.value)}
              >
                <option value="">{tt('Elige', locale)}</option>
                { [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(optionAge => (
                  <option key={optionAge} value={optionAge}>
                    {optionAge} {tt((optionAge === 1) ? 'Año' : 'Años', locale)}
                  </option>
                ))}
              </select>
            </div>
          ))
          }
      </div>
    );
  }
}

export default injectIntl(AgeSelectorMobile);
