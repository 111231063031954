import React from 'react';
import './promotional-code.scss';
import { injectIntl, intlShape } from 'react-intl';
import tt from '../../../helpers/translation';


const PromotionalCode = (context) => {
  const { locale } = context.intl;
  return (
    <div className="promotional-code-body">
      <input type="text" placeholder={tt('Código Promocional', locale)} className="promotional-code" />
    </div>
  );
};

export default injectIntl(PromotionalCode);
