const redirectFunnelHoteles = (
  locale,
  selectedDateFrom,
  selectedDateTo,
  handleSelectedAdult,
  handlePromotionalCode,
  handleKidsPicker
) => {
  const formatDate = (date) => date.split('-').reverse().join('-');

  const params = {
    dateIn: formatDate(selectedDateFrom),
    dateOut: formatDate(selectedDateTo),
    adults: handleSelectedAdult,
    rooms: 1,
    ...(handleKidsPicker.length > 0 && {
      childs: handleKidsPicker.length,
      ages: handleKidsPicker.join(';'),
    }),
  };

  const url = `https://book.portaventuraworld.com/${locale}/hotels?`;

  const urlParams = Object.entries(params).map(([key, value]) => `${key}=${value}`);

  const sendUrl = url + urlParams.join('&');

  window.location.assign(sendUrl);
};

export default redirectFunnelHoteles;
