import React from 'react';
import './rooms.scss';
import onClickOutside from 'react-onclickoutside';
import { injectIntl, intlShape } from 'react-intl';
import NumberSelector from './NumberSelector.js';
import tt from '../../../helpers/translation';

class Rooms2 extends React.Component {
  state = {
    showMainContainer: false,
    showPanelClass: 'panel-one-room',
    showPanelColumns: 'is-12',
    rooms: this.props.values.rooms,
    visible_rooms: this.props.values.visible_rooms,
    errorMaxPeopleReached: false,
  };

  componentDidMount = () => {
    this.updateMainDiv();
  };

  handleClickMain = () => {
    this.setState(
      {
        showMainContainer: !this.state.showMainContainer,
      },
      () => this.updateMainDiv()
    );
  };

  handleClickOutside = (evt) => {
    this.setState({ showMainContainer: false });
  };

  checkLimitOf9 = () => {
    const adults =
      this.state.rooms[0].adults + this.state.rooms[1].adults + this.state.rooms[2].adults;
    const kids = this.state.rooms[0].kids + this.state.rooms[1].kids + this.state.rooms[2].kids;
    if (adults + kids === 9) {
      this.setState({ errorMaxPeopleReached: true });
      setTimeout(() => this.setState({ errorMaxPeopleReached: false }), 5000);
      return false;
    }
    return true;
  };

  updatePanelSize = () => {
    let panel_class = 'panel-one-room';
    let panel_columns = 'is-12';
    if (this.state.visible_rooms === 2) {
      panel_class = 'panel-two-rooms';
      panel_columns = 'is-6';
    }
    if (this.state.visible_rooms === 3) {
      panel_class = 'panel-three-rooms';
      panel_columns = 'is-4';
    }
    this.setState({
      showPanelClass: panel_class,
      showPanelColumns: panel_columns,
    });
  };

  addRoom = () => {
    if (this.checkLimitOf9()) {
      const { rooms: newRooms } = this.state;
      newRooms[this.state.visible_rooms] = {
        visible: true,
        adults: 1,
        kids: 0,
        ages: [-1, -1, -1, -1, -1, -1],
      };
      const newNumerOfRooms = this.state.visible_rooms + 1;
      this.setState(
        {
          visible_rooms: newNumerOfRooms,
          rooms: newRooms,
        },
        () => {
          this.updatePanelSize();
          this.updateMainDiv();
        }
      );
    }
  };

  closeRoom = () => {
    const { rooms: newRooms } = this.state;
    newRooms[this.state.visible_rooms - 1] = { visible: false, adults: 0, kids: 0 };
    this.setState(
      {
        visible_rooms: this.state.visible_rooms - 1,
        rooms: newRooms,
      },
      () => {
        this.updatePanelSize();
        this.updateMainDiv();
      }
    );
  };

  roomPeople = (roomId) => this.state.rooms[roomId].adults + this.state.rooms[roomId].kids;

  countAdults = () =>
    this.state.rooms[0].adults + this.state.rooms[1].adults + this.state.rooms[2].adults;

  countKids = () => this.state.rooms[0].kids + this.state.rooms[1].kids + this.state.rooms[2].kids;

  updateMainDiv = () => {
    const textRooms =
      this.state.visible_rooms === 1
        ? `${this.state.visible_rooms} ${tt('Hab', this.props.intl.locale)}, `
        : `${this.state.visible_rooms} ${tt('Hab', this.props.intl.locale)}., `;
    const textAdults =
      this.countAdults() === 1
        ? `${this.countAdults()} ${tt('Adulto', this.props.intl.locale)}`
        : `${this.countAdults()} ${tt('Adultos', this.props.intl.locale)}`;
    let textKids;
    if (this.countKids() === 0) {
      textKids = '';
    } else if (this.countKids() === 1) {
      textKids = `, ${this.countKids()} ${tt('Niño', this.props.intl.locale)}`;
    } else {
      textKids = `, ${this.countKids()} ${tt('Niños', this.props.intl.locale)}`;
    }
    this.setState({ mainDivText: `${textRooms}${textAdults}${textKids}` });
  };

  addAdult = (roomId) => {
    if (this.roomPeople(roomId) < 6) {
      if (this.checkLimitOf9()) {
        const currentRooms = this.state.rooms;
        currentRooms[roomId].adults += 1;
        this.setState({ rooms: currentRooms }, () => this.updateMainDiv());
      }
    }
  };

  addKid = (roomId) => {
    if (this.roomPeople(roomId) < 6) {
      if (this.checkLimitOf9()) {
        const currentRooms = this.state.rooms;
        currentRooms[roomId].kids += 1;
        currentRooms[roomId].ages[currentRooms[roomId].kids - 1] = 'Seleccionar';
        this.setState({ rooms: currentRooms }, () => this.updateMainDiv());
      }
    }
  };

  removeAdult = (roomId) => {
    if (this.state.rooms[roomId].adults > 1) {
      const currentRooms = this.state.rooms;
      currentRooms[roomId].adults -= 1;
      this.setState({ rooms: currentRooms }, () => this.updateMainDiv());
    }
  };

  removeKid = (roomId) => {
    if (this.state.rooms[roomId].kids > 0) {
      const currentRooms = this.state.rooms;
      currentRooms[roomId].kids -= 1;
      currentRooms[roomId].ages[currentRooms[roomId].kids] = -1;
      this.setState({ rooms: currentRooms }, () => this.updateMainDiv());
    }
  };

  updateAge = (event, roomid, kid) => {
    const currentRooms = this.state.rooms;
    currentRooms[roomid].ages[kid] = parseInt(event.target.value);
    this.setState({ rooms: currentRooms });
  };

  acceptConfig = () => {
    this.setState(
      {
        showMainContainer: false,
      },
      () => {
        const roomsdata = {
          rooms: this.state.rooms,
          visible_rooms: this.state.visible_rooms,
        };
        this.props.action(roomsdata);
      }
    );
  };

  kidsSelector = (roomid, kid) => {
    const edad = this.state.rooms[roomid].ages[kid];
    return (
      <div className="select is-rounded kidsselect">
        <select
          required
          value={edad}
          onChange={(event) => this.updateAge(event, roomid, kid)}
          name="age-kids"
        >
          <option disabled value="Seleccionar">
            {tt('Elige', this.props.intl.locale)}
          </option>
          {/* <option value="0">0 {tt('años', this.props.intl.locale)}</option>
          <option value="1">1 {tt('años', this.props.intl.locale)}</option> */}
          <option value="2">2 {tt('años', this.props.intl.locale)}</option>
          <option value="3">3 {tt('años', this.props.intl.locale)}</option>
          <option value="4">4 {tt('años', this.props.intl.locale)}</option>
          <option value="5">5 {tt('años', this.props.intl.locale)}</option>
          <option value="6">6 {tt('años', this.props.intl.locale)}</option>
          <option value="7">7 {tt('años', this.props.intl.locale)}</option>
          <option value="8">8 {tt('años', this.props.intl.locale)}</option>
          <option value="9">9 {tt('años', this.props.intl.locale)}</option>
          <option value="10">10 {tt('años', this.props.intl.locale)}</option>
          <option value="11">11 {tt('años', this.props.intl.locale)}</option>
          <option value="12">12 {tt('años', this.props.intl.locale)}</option>
        </select>
      </div>
    );
  };

  mainContainer = () => (
    <div className="panel-relative">
      <div className={`show-panel ${this.state.showPanelClass}`}>
        <div className="rooms-wrapper">
          <div className="select-rooms columns">
            {[0, 1, 2].map(
              (room) =>
                this.state.rooms[room].visible && (
                  <div key={`key-room-${room}`} className={`column ${this.state.showPanelColumns}`}>
                    <div className="columns">
                      <div className="column is-12 room-name">
                        {tt('Habitación', this.props.intl.locale)} {room + 1}
                        {this.state.visible_rooms > 1 && this.state.visible_rooms - 1 === room && (
                          <span className="closeit" onClick={() => this.closeRoom()}>
                            {' '}
                            ({tt('Eliminar', this.props.intl.locale)})
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column is-6 room-guests">
                        {tt('Adultos', this.props.intl.locale)} (+12)
                      </div>
                      <div className="column is-6 room-guest-selector">
                        <NumberSelector
                          value={this.state.rooms[room].adults}
                          onDec={() => this.removeAdult(room)}
                          onInc={() => this.addAdult(room)}
                        />
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column is-6 room-guests">
                        {tt('Niños', this.props.intl.locale)} (2-12)
                      </div>
                      <div className="column is-6 room-guest-selector">
                        <NumberSelector
                          value={this.state.rooms[room].kids}
                          onDec={() => this.removeKid(room)}
                          onInc={() => this.addKid(room)}
                        />
                      </div>
                    </div>
                    {this.state.rooms[room].kids > 0 && (
                      <div className="kids-ages-wrapper">
                        <div className="kids-ages">
                          {tt('Edad de los niños', this.props.intl.locale)}
                        </div>
                        <div className="columns  is-multiline kids-selectors">
                          {Array.apply('*', { length: this.state.rooms[room].kids }).map((e, i) => (
                            <div
                              className="kid-column column is-half"
                              key={`key-selector-${e}-${i}`}
                            >
                              {' '}
                              {this.kidsSelector(room, i)}{' '}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {this.state.visible_rooms < 3 && this.state.visible_rooms - 1 === room && (
                      <div className="add-room" onClick={() => this.addRoom()}>
                        +{tt('Añadir habitación', this.props.intl.locale)}
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
        </div>
        <div>
          {this.state.errorMaxPeopleReached && (
            <div>{tt('Máximo: 9 personas', this.props.intl.locale)}</div>
          )}
        </div>
        {/* <button  onClick={() => this.acceptConfig()}>Aceptar</button> */}
      </div>
    </div>
  );

  render() {
    return (
      <div className="rooms-container">
        <div className="input is-rounded is-narrow" id="room-configuration2">
          <span onClick={this.handleClickMain}>{this.state.mainDivText}</span>
          <div>{this.state.showMainContainer && this.mainContainer()}</div>
        </div>
      </div>
    );
  }
}

export default injectIntl(onClickOutside(Rooms2));
