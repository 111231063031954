import React from 'react';
import './NumberSelector.scss';

class NumberSelector extends React.PureComponent {
  render() {
    const { onDec, onInc, value } = this.props;
    return (
      <div className="number-input">
        <button type="button" className="minus" onClick={onDec} />
        <input className="quantity" min="0" name="quantity" value={value} type="number" />
        <button type="button" className="plus" onClick={onInc} />
      </div>
    );
  }
}

export default NumberSelector;
